<template>
  <div class="h-full select-none bg-grey-light-03 pb-36 md:flex md:pb-0">
    <div class="aspect-[4/3] w-full shrink-0 md:aspect-[4/5] md:w-[36%]">
      <MediaImage
        v-if="image"
        v-bind="image"
        class="h-full"
        img-class="h-full w-full object-cover"
        :image-style="imageStyle"
      />
    </div>
    <div
      class="break-word mt-64 flex w-full items-center text-balance px-30 text-center md:mt-0 md:px-56"
    >
      <div class="w-full">
        <h3 class="text-[16px] font-light uppercase">{{ overline }}</h3>
        <Text
          class="paragraph-heading mt-16 text-[24px]/[32px] font-light uppercase md:text-[40px]/[48px]"
          :class="{
            '!font-bold': isRebranding,
          }"
        >
          {{ headline }}
        </Text>
        <div
          class="rich-text mt-24 text-base font-light md:text-[18px]/[32px]"
          v-html="description"
        ></div>
        <ButtonsLinkButton
          v-if="link?.uri?.path?.length"
          class="link-button mt-[45px] inline-block md:mt-24"
          :url="link?.uri?.path"
          span-style="padding-bottom: 5px"
        >
          {{ $texts('readMore', 'Read more') }}
        </ButtonsLinkButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphRelatedSliderFragment } from '#graphql-operations'

const { $texts } = useEasyTexts()
const isRebranding = await useFeatureFlag()

defineProps<{
  overline?: string
  headline?: string
  description?: string
  link?: ParagraphRelatedSliderFragment['link']
  image?: ParagraphRelatedSliderFragment['image']
}>()

const imageStyle = defineImageStyle({
  type: 'pictures',
  pictures: {
    sm: {
      width: 768,
      aspectRatio: 4 / 3,
    },
    md: {
      width: 380,
      aspectRatio: 4 / 5,
    },
  },
})
</script>
